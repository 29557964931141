<!-- 登录页 -->
<div class="login-block">
    <!-- 中间部分 -->
    <div class="login-content">
        <div class="left">
            <img src="../../assets/images/loginLeft.png">
        </div>
        <div class="right" *ngIf="isShowRegister">
            <div class="loginTxt-header flex_l">
                <div (click)="changeLoginType('scan',$event)" [ngClass]="{'header_line':loginType=='scan'}">注册/登录</div>
            </div>
            <!-- 主体部分  Phone-->
            <div class="loginTxt-content">
                <!-- 账号 -->
                <div class="login-list">
                    <img src="assets/images/login-user.png" alt=""><input [(ngModel)]="loginName" type="text" placeholder="请输入手机号码" />
                </div>
                <!-- 图形验证码 -->
                <div class="login-ImgCode login-list">
                    <img src="assets/images/login-imgCode.png" alt=""><input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                    <div (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
                </div>
                <!-- 手机验证码 -->
                <div class="login-code login-list">
                    <img src="assets/images/login-code.png" alt=""><input [(ngModel)]="vcodeText" type="text" (keyup)="inputKeyup($event)" placeholder="请输入手机验证码" />
                    <div *ngIf="!isSendOver && !countDown" (click)="postMobileSmsVcode()">获取验证码</div>
                    <div *ngIf="countDown">{{countDown}}</div>
                    <div *ngIf="isSendOver && !countDown" (click)="postMobileSmsVcode()">重新发送</div>
                </div>
            </div>
            <!-- 尾部 -->
            <div class="login-foot">
                <!--<div (click)="isRememberAccount=!isRememberAccount" [class.isRemenber]="isRememberAccount" class="loginFoot-head">记住账号</div>-->
                <div class="loginFoot-txt"><button (click)="gotoRegister()">立即注册/登录</button></div>
                <p (click)="isShowRegister=false;showWXCode=false;loginType='scan'" style="text-align: center;color: #59499a;margin-top: 5px">已有账号，前往登录</p>
            </div>
        </div>
        <!-- 右侧登录功能部分 -->
        <div class="right" *ngIf="!isShowRegister">
            <!-- 头部 -->
            <div class="loginTxt-header flex_l">
                <div (click)="changeLoginType('scan',$event)" [ngClass]="{'header_line':loginType=='scan'}">扫码登录</div>
                <div style="margin: 0 15px">|</div>
                <!--<div (click)="changeLoginType('phone',$event)" [ngClass]="{'header_line':loginType=='phone'}">手机号登录</div>-->
                <div (click)="changeLoginType('email',$event)" [ngClass]="{'header_line':loginType=='email'}">邮箱登录</div>
            </div>

            <div class="loginTxt-content" *ngIf='loginType=="scan"' style="position: relative">
                <p class="scan_title" *ngIf="!showWXCode">点击下方二维码，使用微信扫码登录</p>
                <div class="scan_bg" *ngIf="!showWXCode">
                    <div class="getWXCodeBtn" (click)="initWeiChatLogin()">点击扫码登录</div>
                </div>
                <div style="width: 300px;height: 410px;overflow: hidden;margin-left: calc((100% - 300px)/2)" id="login_container">
                </div>

                <div (click)="noneBindWxChipClick('register')" class="bottomBtns">注册账号/手机号登录</div>
            </div>

            <!-- 主体部分  email-->
            <div class="loginTxt-content" *ngIf='loginType=="email"'>
                <!-- 账号 -->
                <div class="login-list">
                    <img src="assets/images/login-code.png" alt=""><input [(ngModel)]="loginEmail" type="text" placeholder="请输入邮箱" />
                </div>
                <!-- 密码 -->
                <div class="login-list">
                    <img src="/assets/images/EmailPsw02.png" alt=""><input [(ngModel)]="loginEmailPsw" type="password" placeholder="请输入密码" />
                </div>
                <!-- 手机验证码 -->
                <div class="login-ImgCode login-list">
                    <img src="assets/images/login-imgCode.png" alt=""><input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                    <div (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
                </div>
            </div>

            <!-- 尾部 -->
            <div class="login-foot" *ngIf="loginType=='email'">
                <!--<div *ngIf='loginType=="phone"' (click)="isRememberAccount=!isRememberAccount" [class.isRemenber]="isRememberAccount" class="loginFoot-head">记住账号</div>-->
                <div *ngIf='loginType=="email"' (click)="isRememberAccountClick()" [class.isRemenber]="isRememberAccount1" class="loginFoot-head">记住邮箱</div>
                <div class="loginFoot-txt"><button (click)="postMobileSmsCode()">{{loginType=='phone'?'登录或注册':'登录'}}</button></div>
                <div class="flex_s">
                    <a *ngIf='loginType=="email"' (click)="noneBindWxChipClick('register')" class="bottomBtn">注册账号/手机号登录</a>
                    <a *ngIf='loginType=="email"' (click)="gotoFindPsw()" class="bottomBtn">忘记密码</a>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [showHeader]="false" [closeOnEscape]="false" [(visible)]="updateLoginEmailPsw" [style]="{'width':650,'height':360,'minHeight':360}" [modal]=true>
    <p style="text-align: center;margin-top: 40px;color: red">当前密码安全等级较低，请立即修改新密码</p>
    <div class="sign-list">
        <span>新密码：</span><input type={{seePswType2}} [(ngModel)]="updatePswData.newPsw" placeholder="请填写新密码(数字加字母组合)">
        <img *ngIf="seePswType2=='text'" (click)="changeSeePsw(2,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/no_seePsw.png">
        <img *ngIf="seePswType2=='password'" (click)="changeSeePsw(2,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/seePsw..png">
    </div>
    <div class="sign-list">
        <span>确认密码：</span><input type={{seePswType3}} [(ngModel)]="updatePswData.okPsw" placeholder="请填写确认密码(数字加字母组合)">
        <img *ngIf="seePswType3=='text'" (click)="changeSeePsw(3,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/no_seePsw.png">
        <img *ngIf="seePswType3=='password'" (click)="changeSeePsw(3,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/seePsw..png">
    </div>
    <div class="sign-list">
        <div style="font-size: 10px;color: lightgray;margin-top: -15px;margin-left: 140px" class="flex_l">
            <p style="color: red">*</p>格式：长度8位，由不连续的字母和数字2种构成</div>
    </div>
    <button class="updateEmailBtnClick" (click)="savePsw()">保存</button>
</p-dialog>

<div class="screenModel" *ngIf="config.isScreenSmallBox">
    <div class="screenModelTitle flex_l"><img src="../../assets/images/orange_warning.png" />分辨率过低</div>
    <img class="screenModelClose" (click)="closescreenModel()" src="../../assets/images/close_default.png" />
    <div class="screenModel_content">你当前的分辨率小于1680*1050，会影响使用体验，建议你切换至合适的屏幕分辨率 （部分页面支持“CTRL和减号键”进行尺寸调整）</div>
</div>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="config.chrome_core" [style]="{'width':560,'height':280,'minHeight':280,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="system_out_bg">
        <div style="height: 90px;margin: 95px 77px">
            <img class="system_out_diannao" src="../../assets/images/diannao.png" />
            <li style="margin-left: 30px"> <img style="width: 16px;height: 16px;margin-right: 5px;margin-top: -5px;" src="../../assets/images/chips_yellow.png" /> <span style="font-size:16px;color: #5E4FFF ">系统提示</span></li>
            <li style="margin-left: 30px;margin-top: 5px;"><span style="color: #666666;font-size: 16px;">您使用的浏览器不兼容，可能会影响您的使用，推荐使用谷歌浏览器</span></li>
        </div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="noneBindWxChips" [style]="{'width':511,'height':320,'minHeight':511,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="noneBindWxChips">
        <img class="close" src="../../assets/images/closewxBind.png" (click)="closeWeixinBingChips()" />
        <p class="wxtitle">当前微信暂未绑定账号</p>
        <div class="noneBindWxChipBtn flex_s">
            <div style="width: 128px" (click)="noneBindWxChipClick('register')">注册账号</div>
            <div style="width: 215px" (click)="noneBindWxChipClick('bind')">已有账号,前往绑定</div>
        </div>
        <p class="chips" style="margin-top: 45px">1、没有注册过账号的用户可以点击注册账号进行注册</p>
        <p class="chips">2、已注册账号的用户可以通过手机号和微信绑定，绑定后可直接扫码登录</p>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowBindModel" [style]="{'width':500,'height':500,'minHeight':500,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="BindWxModel">
        <p class="BindWxModel_title">微信账号绑定</p>
        <!-- 主体部分  Phone-->
        <div class="loginTxt-content" style="padding: 0;padding-left: 15px">
            <!-- 账号 -->
            <div class="login-list">
                <img src="assets/images/login-user.png" alt=""><input [(ngModel)]="loginName" type="text" placeholder="请输入绑定手机号码" />
            </div>
            <!-- 图形验证码 -->
            <div class="login-ImgCode login-list">
                <img src="assets/images/login-imgCode.png" alt=""><input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                <div (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
            </div>
            <!-- 手机验证码 -->
            <div class="login-code login-list">
                <img src="assets/images/login-code.png" alt=""><input [(ngModel)]="vcodeText" type="text" (keyup)="inputKeyup($event)" placeholder="请输入手机验证码" />
                <div *ngIf="!isSendOver && !countDown" (click)="postMobileSmsVcode()">获取验证码</div>
                <div *ngIf="countDown">{{countDown}}</div>
                <div *ngIf="isSendOver && !countDown" (click)="postMobileSmsVcode()">重新发送</div>
            </div>
        </div>
        <div class="login-foot">
            <div class="loginFoot-txt"><button (click)="bindWxClick()">绑定</button></div>
        </div>
    </div>
</p-dialog>

<!-- 
<div class="topTip">
    <div class="center">
        <marquee loop="loop" scrollamount="5" class="notice-list">
            尊敬的用户：随着工作啦用户数量持续增加，为了更好地满足用户使用需求，平台已于2021年11月29日晚11时—11月30日6时进行服务器及网络资源的扩容升级。扩容升级后，如您操作出现软件和网络方面的问题，请及时与客服取得联系，客服电话：4006611396。
        </marquee>
    </div>
</div> -->