import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelCompanyPhoneComponent } from '../model-company-phone/model-company-phone.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
declare let layui;

declare var window;
@Component({
  selector: 'app-company-account',
  templateUrl: './company-account.component.html',
  styleUrls: ['./company-account.component.css']
})
export class CompanyAccountComponent implements OnInit {
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @ViewChild('phone', { static: true }) child: ModelCompanyPhoneComponent;
  @ViewChild('crop', { static: true }) child1: ModelCropperComponent;
  public config: Config;
  public myUser: any;
  //需要提交服务器的信息
  public userData = {
    name: '',
    post: '',
    gender: '01',//性别
    email: '',//工作邮箱
    weixin: '',
    loginEmail: '',
    emailCode: '',
    login_email: '',
    email_actived: false
  };
  public updateEmailData = {
    oldEmail: '',
    newEmail: '',
    newEmailCode: '',
  }
  public updatePswData = {
    oldPsw: '',
    newPsw: '',
    okPsw: '',
  }
  public loginPhone = '';//绑定手机号
  public isName = false;//是否允许修改姓名

  public updateLoginEmailModel = false;//修改邮箱
  public updateLoginEmailPsw = false;//修改密码

  public BindEmailSuccessModel = false;//绑定登录邮箱成功弹窗

  public seePswType = 'password';
  public seePswType2 = 'password';
  public seePswType3 = 'password';

  public sendEmailText1 = '获取邮箱验证码';
  public sendEmailText2 = '获取邮箱验证码';

  public timer1 = null;
  public timer2 = null;
  public isTuiJian = false;

  /** 绑定登录邮箱展示 */
  public bingLoginEmailDialogVisible = false;
  public bingLoginEmailForm = {
    email: '',
    code: '',
    psw: '',
    psw2: ''
  };
  public IsBindWeiChat = false
  public showDeleteBingWeiChatModel = false;
  public showBingWeiChatModel = false;
  public deleteWX = {
    phone: '',
    code: ''
  }
  public weChatCode = "";
  public checkWeChatTimer = null
  public checkWeChatIndex = 60;

  public countDown = "发送验证码"
  public isSending = false
  public timer;

  public contact_info_switch = {
    isData: false,
    is_public_mobile: true,
    is_public_wx: true,
    is_public_email: true
  }
  public isShowMemberMoudel = false;
  public openProTip = false;
  public openModify = false;
  public modifyValue = '';
    /** 密码强度 */
    public password_strength = 0;
    public password_strength_message = '';
  
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public formatDate: FormatDate,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.vipState((isPay) => {
      this.isShowMemberMoudel = isPay;
    })
    this.opera.initConfig('company', 'company-account');

    this.checkIdentity();//检查当前认证状态

    this.getUser();//获取用户信息

    this.getDepartment();

    this.getIsBindWeiChat()

  }

  modifyName() {
    if (this.isShowMemberMoudel) {
      this.openProTip = true;
      return
    }
    this.modifyValue = '';
    this.openModify = true;
  };

  saveBtnClick() {
    if (!this.modifyValue) {
      this.toastr.error('请填写新的联系人姓名');
      return
    };
    this.dataApi.uplinkman({ value: this.modifyValue }).then((res: any) => {
      this.toastr.success('修改成功');
      this.getUser();
      this.openModify = false;
    }).catch((error) => {
      console.log(error);
    });
  }

  //修改手机号
  uploadPhone() {
    this.modelShow = true;
    this.opera.scrollTop();
    this.child.initData();
  }

  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      //修改绑定手机号
      this.myUser = this.dataApi.getLocalLoginUser();
      this.loginPhone = this.myUser.mobile;
    }
  }
  /**
   * 获取企业认证信息
   */
  checkIdentity() {
    this.dataApi.getLocalDepartment();
    this.config.department = this.dataApi.getLocalDepartment();
    if (this.config.department && this.config.department.is_certified) {
      this.isName = true;
    } else {
      this.dataApi.getPlatformApply().then((res: any) => {
        //console.log("获取到企业认证信息------------------------account");
        if (res) {
          this.config.lastIdentity = res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
          if (res.status == 2) {
            this.config.department.is_certified = true;
            this.dataApi.setLocalDepartment(this.config.department);
            this.isName = true;
          } else {
            this.isName = false;
          }
        }
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
        this.isName = false;
      })
    }
  }
  /**
   * 获取用户信息
   */
  getUser() {
    this.dataApi.getUser().then((res: any) => {
      //console.log("线上数据hr信息------------------------aboutsettinguser");
      //console.log(res);
      if (res) {
        this.myUser = res;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.userData.name = this.myUser.name;
        this.userData.post = this.myUser.post;
        this.userData.weixin = this.myUser.weixin;
        this.userData.email = this.myUser.email;
        this.userData.gender = this.myUser.gender;
        this.userData.login_email = this.myUser.login_email == null ? "" : this.myUser.login_email
        this.userData.email_actived = this.myUser.email_actived
        this.loginPhone = this.myUser.mobile;
        this.updateEmailData.oldEmail = this.userData.login_email;
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  getDepartment() {
    this.dataApi.getDepartment().then((res: any) => {
      if (res) {
        this.dataApi.setLocalDepartment(res);
        this.contact_info_switch.isData = true
        this.contact_info_switch.is_public_email = res.contact_info_list.email.is_public
        this.contact_info_switch.is_public_mobile = res.contact_info_list.mobile.is_public
        this.contact_info_switch.is_public_wx = res.contact_info_list.wx.is_public

      }
    }).catch((error) => {
    });
  }

  //验证邮箱
  checkEmail() {
    let phoneModel = {
      format_type: 2,//0:手机号，1：手机号&固话；2.邮箱
      data: this.userData.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (!res.value) {
        this.toastr.error('工作邮箱格式错误');
        return;
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })
  }

  //保存修改人才信息
  updateUser() {
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    //---必填字段检查
    if (!this.userData.name) {
      this.toastr.warning('请填写姓名');
      return;
    } else {
      if (!filter.test(this.userData.name)) {
        this.toastr.warning("姓名只能填写汉字或字母");
      }
    }

    if (this.userData.emailCode != '' || this.userData.loginEmail != '') {
      this.toastr.warning("登录邮箱请先验证");
      return;
    }

    if (!this.userData.post) {
      this.toastr.warning('请填写职位');
      return;
    } else {
      if (!filter.test(this.userData.name)) {
        this.toastr.warning("姓名只能填写汉字或字母");
      }
    }

    if (!this.userData.email) {
      this.toastr.warning('请填写工作邮箱');
      return;
    } else {
      this.checkEmail();
    }

    //null值提交会被判断成字符串保存
    if (!this.userData.weixin) {
      this.userData.weixin = "";
    }

    //----微信号正则验证表达式
    // let fitter=/^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}+$/;
    // let loader = this.tip.presentLoading('正在处理中...');
    this.dataApi.updateUser(this.userData).then((res: any) => {
      this.myUser.name = this.userData.name;
      this.myUser.post = this.userData.post;
      this.myUser.weixin = this.userData.weixin;
      this.myUser.email = this.userData.email;
      this.myUser.gender = this.userData.gender;
      this.dataApi.setLocalLoginUser(this.myUser);
      // loader.dismiss();
      setTimeout(() => {
        this.toastr.success('保存成功');
      }, 600);
      // this.navCtrl.pop(); //-------返回上级页面
    }).catch((error) => {
      // loader.dismiss();
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);
    })
  }

  //上传logo
  previewPic(event) {
    let imgData = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.imageCropper(reader.result);
        // that.uploadDepImg(reader.result);
      };

    }
  }

  //裁剪插件
  imageCropper(imageData) {
    this.modelTwoShow = true;
    this.child1.initData(imageData);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if (event) {
      this.uploadDepImg(event);
    }
  }

  //上传
  uploadDepImg(data) {
    let imageData = data.replace(/^(data:image)\/png;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/jpeg;base64,/g, '');
    this.opera.loading();
    this.dataApi.updateUserAvatar({ value: imageData }).then((res: any) => {
      this.myUser.avatar = res.value;
      this.config.loginUser.avatar = this.myUser.avatar;
      this.dataApi.setLocalLoginUser(this.config.loginUser);
      this.opera.loadiss();
      this.toastr.success("上传成功");
    }).catch((error) => {
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
      this.opera.loadiss();
    })
  }

  /**
   * 发送邮件验证
   */
  sendEmail(type) {
    // type 1 第一次绑定。2。修改绑定
    if (type == 1) {
      console.log("userData.loginEmail", this.userData.loginEmail);
      // if(!this.isEmail(this.userData.loginEmail))
      // {
      //   this.toastr.error("邮箱格式错误");return;
      // }

      let data = {
        email: this.userData.loginEmail,
      };
      if (this.sendEmailText1 != '获取邮箱验证码') {
        this.toastr.error('60秒内不能重复发送')
        return;
      }
      this.dataApi.sendEmail(data).then((res: any) => {
        console.log("发送邮箱", res, data);
        this.toastr.success('获取成功')

        let count = 60;
        this.sendEmailText1 = count + 's';
        this.timer1 = setInterval(() => {
          count -= 1;
          this.sendEmailText1 = count + 's';
          if (count === 0) {
            clearInterval(this.timer1);
            this.sendEmailText1 = '获取邮箱验证码';
          }
        }, 1000);



      }).catch((err) => {
        console.log("发送邮箱失败", err);
        this.toastr.error(err); return;
      })

    }
    else {
      // if(!this.isEmail(this.updateEmailData.newEmail))
      // {
      //   this.toastr.error("邮箱格式错误");return;
      // }
      if (this.updateEmailData.oldEmail == this.updateEmailData.newEmail) {
        this.toastr.error("原邮箱和新邮箱不能相同");
        return;
      }
      if (this.sendEmailText2 != '获取邮箱验证码') {
        this.toastr.error('60秒内不能重复发送');
        return;
      }
      let accountid = this.local.get("accountid");
      if (accountid) {
        let data = {
          email: this.updateEmailData.newEmail,
        };
        this.dataApi.sendEmail(data).then((res: any) => {
          console.log("发送邮箱", res, data);
          this.toastr.success('发送成功');
          let count = 60;
          this.sendEmailText2 = count + 's';
          let timer = setInterval(() => {
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0) {
              clearInterval(timer);
              this.sendEmailText2 = '获取邮箱验证码';
            }
            if (this.updateEmailData.newEmail == '') {
              clearInterval(timer);
              this.sendEmailText2 = '获取邮箱验证码';
            }

          }, 1000);

        }).catch((err) => {
          console.log("发送邮箱失败", err);
          this.toastr.error(err); return;
        })
      }
      else {
        this.toastr.error("发送邮箱失败;错误码：E0001"); return;
      }
    }

  }

  isEmail(test) {
    return /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(test);
  }

  /**
   * 更新邮箱弹窗事件
   */
  updateEmail() {
    this.updateLoginEmailModel = true;
    this.updateEmailData.newEmail = '';
  }

  /**
   * 更新密码弹窗事件
   */
  updatePsw() {
    this.updateLoginEmailPsw = true;
    this.updatePswData.okPsw = '';
    this.updatePswData.oldPsw = '';
    this.updatePswData.newPsw = '';
  }

  /**
   * 关闭更新密码/邮箱事件
   */
  closeModel() {
    this.updateLoginEmailModel = false;
    this.updateLoginEmailPsw = false;
    this.BindEmailSuccessModel = false;
  }

  /**
   * 保存更新邮箱确定按钮
   */
  saveEmail() {

    console.log("saveEmail", this.updateEmailData);
    if (this.updateEmailData.oldEmail == '' || this.updateEmailData.newEmail == '' || this.updateEmailData.newEmailCode == '') {
      this.toastr.error('信息不完整');
      return;
    }
    let data = {
      email: this.updateEmailData.newEmail,
      vcode_text: this.updateEmailData.newEmailCode,
      action_type: "change",
    };
    this.dataApi.changeEmail(data).then((res: any) => {
      console.log("修改成功", res);
      this.toastr.success('修改成功！');
      this.updateLoginEmailModel = false;
      this.userData.login_email = this.updateEmailData.newEmail;
      this.updateEmailData.newEmailCode = '';
      this.updateEmailData.oldEmail = this.updateEmailData.newEmail;
      this.updateEmailData.newEmail = '';

    }).catch((err) => {
      console.log("修改邮箱失败", err);
      this.toastr.error(err); return;
    })

  }

  /**
   * 更新密码确定按钮
   */
  savePsw() {
    if (this.updatePswData.newPsw == '' || this.updatePswData.okPsw == '' || this.updatePswData.oldPsw == '') {
      this.toastr.error("密码为空！");
      return;
    }
    if (this.password_strength < 3) {
      this.toastr.error("新密码强度低");
      return;
    }
    if (this.updatePswData.newPsw != this.updatePswData.okPsw) {
      this.toastr.error("两次密码不一致");
      return;
    }

    let data = {
      oldpassword: this.updatePswData.oldPsw,
      newpassword: this.updatePswData.newPsw,
    };

    this.dataApi.changePsw(data).then((res: any) => {
      console.log("修改成功", res);
      this.toastr.success('修改成功！');
      this.updateLoginEmailPsw = false;
      this.password_strength = 0
      this.password_strength_message = ''
    }).catch((err) => {
      console.log("修改邮箱失败", err);
      this.toastr.error(err);
    })
  }
  isPsw(psw) {
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,25}$/;
    return reg.test(psw)
  }
  changeSeePsw(index, type) {
    if (index == 1) {
      this.seePswType = type;
    }
    if (index == 2) {
      this.seePswType2 = type;
    }
    if (index == 3) {
      this.seePswType3 = type;
    }
  }
  tuijianChanged(n) {
    this.isTuiJian = !this.isTuiJian;
    let data = { value: this.isTuiJian }
    this.dataApi.setDepartmentTuiJian(data).then((res: any) => {
      console.log("发送邮箱", res);
    }).catch((err) => {
      console.log("发送邮箱失败", err);
      this.toastr.error(err);
    })
  }
  //这是用户信息是否公开
  setUserInfoOpen(type, isOpen) {
    let that = this
    console.log('设置', type, isOpen)
    layui.use('layer', function () {
      var layer = layui.layer;
      let alert = layer.confirm('确定修改吗？', {
        btn: ['确定', '取消'],
        title: '设置'
      }, function () {
        layer.close(alert);
        let data
        if (type == 'email') {
          data = {
            type: 'email',
            is_public: isOpen
          }
        }
        if (type == 'phone') {
          data = {
            type: 'mobile',
            is_public: isOpen
          }
        }
        if (type == 'wx') {
          data = {
            type: 'wx',
            is_public: isOpen
          }
        }
        that.dataApi.setDepartmentSwitchContactinfo(data).then((res: any) => {
          that.toastr.success('设置成功')
          if (type == 'email') {
            that.contact_info_switch.is_public_email = isOpen
          }
          if (type == 'phone') {
            that.contact_info_switch.is_public_mobile = isOpen
          }
          if (type == 'wx') {
            that.contact_info_switch.is_public_wx = isOpen
          }
        }).catch((error) => {

          that.toastr.error(error)
        })

      }, function () {
        // layer.msg('已取消购买', {icon: 2});
      });

    });
  }
  getIsBindWeiChat() {
    let accountid = this.local.get('accountid');
    let loginUser = this.local.get('loginUser');
    if (loginUser) {
      let user = JSON.parse(loginUser)
      if (user.mobile) {
        this.deleteWX.phone = user.mobile
      }
      if (user.wechat_info) {
        console.log('getIsBindWeiChat', user)
        if (user.wechat_info.unionid == '') {
          user.wechat_info.unionid = null;
        }
        if (user.wechat_info.unionid != null) {
          this.IsBindWeiChat = true
        }
      }
    }
  }

  showBindWeiChatClick() {
    if (this.IsBindWeiChat) {
      console.log('解绑')
      this.deleteWX.code = "";
      this.showDeleteBingWeiChatModel = true
    } else {
      console.log('展示二维码绑定微信')
      if (this.local.get('department')) {
        let department = this.local.get('department');
        let departmentJSON = JSON.parse(department);
        let departmentid = departmentJSON.accountid;
        let data = { departmentid: departmentid };
        //getWeiChatCode
        this.dataApi.getWeiChatCode(data).then((res: any) => {
          if (res) {
            this.weChatCode = res.value
            this.showBingWeiChatModel = true
            this.startCheckBingWx()
          }
          console.log(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error('获取失败，请稍后再试');
        });
      }
    }
  }
  startCheckBingWx() {
    let accountid = this.local.get('accountid');
    this.checkWeChatIndex = 60
    if (this.checkWeChatTimer) {
      clearInterval(this.checkWeChatTimer)
    }
    this.checkWeChatTimer = setInterval(() => {
      this.checkWeChatIndex -= 1;
      if (this.checkWeChatIndex <= 0) {
        this.checkWeChatIndex = 61;
        clearInterval(this.checkWeChatTimer)
      }
      this.dataApi.getComopanyIsBingWeiChat(accountid).then((res: any) => {
        console.log('已绑定')
        this.showBingWeiChatModel = false
        this.checkWeChatIndex = 60;
        if (this.checkWeChatTimer) {
          clearInterval(this.checkWeChatTimer)
        }
        this.IsBindWeiChat = true;
        this.toastr.success('绑定成功')
        this.getUser()

      }).catch((error) => {
        console.log('未绑定')
      })
    }, 1000)
  }
  closeCheckWeiChat() {
    this.checkWeChatIndex = 61;
    this.showBingWeiChatModel = false
    if (this.checkWeChatTimer) {
      clearInterval(this.checkWeChatTimer)
    }
  }
  closeBingWeiChat() {
    this.showDeleteBingWeiChatModel = false
    this.showBingWeiChatModel = false
    this.countDown = '发送验证码';
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  sendSms() {
    if (this.countDown != "发送验证码") {
      this.toastr.warning(this.countDown + '后重新发送');
      return
    }
    this.postMobileSmsVcode()
  }
  // 发送验证码(+验证手机号是否可用)
  postMobileSmsVcode() {
    if (!this.deleteWX.phone) {
      this.toastr.warning('手机号码格式不正确');
      return
    }
    let phoneModel = {
      format_type: 0,//0:手机号，1：手机号&固话；2.邮箱
      data: this.deleteWX.phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (res.value) {
        this.sendPhoneCode();//发送验证码
      } else {
        this.toastr.warning('手机号码格式不正确');
        this.deleteWX.phone = '';
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.warning(err);
    })
  };
  // 发送验证码(+验证手机号是否可用)
  sendPhoneCode() {
    this.opera.loading();
    this.dataApi.postMobileSmsVcodeApp({//获取验证码
      value: this.deleteWX.phone
    }).then((res: any) => {
      this.opera.loadiss();
      this.toastr.success('验证码发送成功！');
      let count = 59;
      this.countDown = count + 's';
      this.isSending = false;
      this.timer = setInterval(() => {
        count -= 1;
        this.countDown = count + 's';
        if (count === 0) {
          clearInterval(this.timer);
          this.countDown = '发送验证码';
        }
      }, 1000);
      //console.log(res);

    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss()
      this.toastr.error(error);
    });
  };
  //解除温馨绑定
  deleteWxBing() {
    if (!this.deleteWX.phone) {
      this.toastr.warning('请输入绑定手机号');
      return;
    }
    if (!this.deleteWX.code) {
      this.toastr.warning('请输入验证码');
      return;
    }
    // unBindWeChat
    let data = {
      mobile: this.deleteWX.phone,
      vcode_text: this.deleteWX.code
    };
    this.dataApi.unBindWeChat(data).then((res: any) => {
      this.toastr.success('解绑绑定');
      this.showDeleteBingWeiChatModel = false;
      this.IsBindWeiChat = false;
      this.getUser();
    }).catch((error) => {
      console.log('未绑定')
      this.toastr.error(error)
    })
  }
    /** 密码失焦 */
    passwordBlur(e) {
      let password = e.target.value
      if(!password) {
        this.password_strength_message = '';
        this.password_strength = 0
        return
      }
      this.dataApi.checkSafetyPassword(password).then((res: any) => {
        this.password_strength = res.score + 1;
        this.password_strength_message = ['','密码强度极低，极易被破解','密码强度低，可不太安全','密码强度中等，存在一定风险','密码强度高，安全性较好','密码强度极高，安全性强'][this.password_strength]
        if(!this.password_strength){
          this.password_strength_message = res.message
        }
      });
    }
    getBingEmailCode() {
      if (!this.bingLoginEmailForm.email) {
        this.toastr.warning('请填写邮箱');
        return;
      }
      if (this.sendEmailText2 != '获取邮箱验证码') {
        this.toastr.error('60秒内不能重复发送');
        return;
      }
      let phoneModel = {
        format_type: 2,//0:手机号，1：手机号&固话；2.邮箱
        data: this.bingLoginEmailForm.email
      }
      this.dataApi.phoneVerification(phoneModel).then((res: any) => {
        if (res.value) {
  
          let data = {
            email: this.bingLoginEmailForm.email,
          };
          this.dataApi.sendEmail(data).then((res: any) => {
            console.log("发送邮箱", res, data);
            this.toastr.success('获取邮箱验证码成功')
            let count = 60;
            this.sendEmailText2 = count + 's';
            this.timer1 = setInterval(() => {
              count -= 1;
              this.sendEmailText2 = count + 's';
              if (count === 0) {
                clearInterval(this.timer1);
                this.sendEmailText2 = '获取邮箱验证码';
              }
            }, 1000);
  
          }).catch((err) => {
            console.log("发送邮箱失败", err);
            this.toastr.error(err); return;
          })
        }
        else {
          this.toastr.error('邮箱格式错误');
        }
      }).catch((err) => {
        //console.log(err);
        this.toastr.error('工作邮箱格式错误');
        return;
      })
  
  
    }
    commitBingLoginEmailForm() {
      if (!this.bingLoginEmailForm.email) {
        this.toastr.error('请输入邮箱'); return;
      }
      if (!this.bingLoginEmailForm.code) {
        this.toastr.error('请输入邮箱验证码'); return;
      }
      if (!this.bingLoginEmailForm.psw) {
        this.toastr.error('请输入登录邮箱密码'); return;
      }
      if(this.password_strength<3){
        this.toastr.error("密码强度低"); return;
      }
      if (!this.bingLoginEmailForm.psw2) {
        this.toastr.error('请输入确认密码'); return;
      }
      if (this.bingLoginEmailForm.psw != this.bingLoginEmailForm.psw2) {
        this.toastr.error('密码不一致'); return;
      }
      this.dataApi.checkPassword(this.bingLoginEmailForm.psw).then((res: any) => {
        let pswData = {
          email: this.bingLoginEmailForm.email,
          vcode_text: this.bingLoginEmailForm.code,
          password: this.bingLoginEmailForm.psw
        }
        this.dataApi.userBindEmailSetPsw(pswData).then((res: any) => {
          this.toastr.success('绑定成功');
          this.bingLoginEmailDialogVisible = false;
          this.password_strength = 0
          this.password_strength_message = ''
          console.log('res', res);
          if (this.myUser) {
            this.myUser.login_email = this.bingLoginEmailForm.email;
            this.userData.login_email = this.bingLoginEmailForm.email;
            this.myUser.email_actived = true
            this.userData.email_actived = true
            this.dataApi.setLocalLoginUser(this.myUser);
          }
  
        }).catch((err) => {
          console.log("修改失败", err);
          this.toastr.error(err); return;
        })
        //userBindEmailSetPsw
      }).catch((err) => {
        console.log("修改失败", err);
        this.toastr.error("密码格式错误，请重新填写"); return;
      })
    }
}
